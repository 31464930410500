<template>
    <div class="uk-container uk-container-expand card-scrollable">
        <div v-if="is_loading.init" class="uk-flex uk-flex-center uk-flex-middle" style="min-height: 100%;">
            <div uk-spinner="ratio: 2"></div>
        </div>

        <div v-else>
            <div
                class="uk-flex uk-flex-middle uk-flex-between"
            >
                <p class="uk-text-bold uk-padding-remove uk-margin-remove">Reimbursement Setup</p>
                <button
                    v-if="$can('CREATE', 'salary-settings')"
                    class="uk-button uk-flex uk-flex-middle uk-flex-center"
                    @click="showModal('add-reimbursement-setup')"
                >
                    <img :src="`${images}/shift-add.png`" alt="Add icon" width="25">
                    <span class="uk-margin-small-left uk-text-bold myrobin-text-1">Add Reimbursement Setup</span>
                </button>
            </div>

            <div class="uk-margin-top">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-middle uk-table-medium uk-table-striped">
                        <thead>
                            <tr>
                                <th class="uk-text-nowrap">No</th>
                                <th class="uk-text-nowrap">Type</th>
                                <th class="uk-text-nowrap">Company Office</th>
                                <th class="uk-text-nowrap">Amount</th>
                                <th class="uk-text-nowrap">Minimum work duration</th>
                                <th class="uk-text-nowrap">Can be requested every</th>
                                <th class="uk-text-nowrap">Apply for Role</th>
                                <th
                                    v-if="$can('EDIT', 'salary-settings') || $can('DELETE', 'salary-settings')"
                                    class="uk-text-nowrap uk-text-center"
                                >Action</th>
                            </tr>
                        </thead>
                        <tbody v-if="reimbursementDatas !== null && reimbursementDatas.docs.length > 0">
                            <tr
                                v-for="(reimbursement, index) in reimbursementDatas.docs"
                                :key="index"
                            >
                                <td class="uk-text-nowrap">{{ index + 1 }}</td>
                                <td class="uk-text-nowrap">{{ reimbursement.type === 1 ? 'General' || '-' : 'Voucher' || '-' }}</td>
                                <td class="uk-text-nowrap">{{ reimbursement.company_office ? reimbursement.company_office.name : 'All Office' }}</td>
                                <td class="uk-text-nowrap">{{ reimbursement.amount === null ? 'By Request' || '-' : `Rp ${formatAmount(reimbursement.amount)}` }}</td>
                                <td class="uk-text-nowrap">{{ `After ${reimbursement.min_work_duration} Months` || '-' }}</td>
                                <td class="uk-text-nowrap">{{ reimbursement.request_duration === null ? 'By Request' || '-' : `${reimbursement.request_duration} Months` || '-' }}</td>
                                <td class="uk-text-nowrap">{{ reimbursement.role ? reimbursement.role.name : 'All Role' }}</td>
                                <td 
                                    v-if="$can('EDIT', 'salary-settings') || $can('DELETE', 'salary-settings')"
                                    class="uk-text-center uk-text-nowrap"
                                >
                                    <button
                                        v-if="$can('EDIT', 'salary-settings')"
                                        class="uk-button uk-button-primary uk-border-rounded"
                                        @click="showModal('edit-reimbursement-setup', reimbursement._id)"
                                    >Edit</button>
                                    <button
                                        v-if="$can('DELETE', 'salary-settings')"
                                        class="uk-button uk-button-danger uk-border-rounded uk-margin-small-left"
                                        @click="showModal('delete-reimbursement-setup', reimbursement._id)"
                                    >Delete</button>
                                </td>
                            </tr>
                        </tbody>
                        <empty-table
                            v-else
                            :colspan="7"
                        />
                    </table>
                </div>
                <pagination
                    v-if="reimbursementDatas !== null"
                    :total-data="reimbursementDatas.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>

            <!-- <div v-else>
                <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                    <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                        <span class="uk-text-large uk-text-bold">
                            <p>Selamat Datang di Company Dashboard</p>
                        </span>
                    </div>
                </div>
            </div> -->

            <!-- Add reimbursement setup modal box -->
            <div id="add-reimbursement-setup" class="uk-flex-top add-reimbursement-setup" uk-modal esc-close="false" bg-close="true">
                <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical border-radius-10px">
                    <p class="uk-text-bold">Add Reimbursement Setup</p>
                    <form class="uk-form-horizontal uk-margin-medium-top" @submit.prevent="addReimbursementSetup">
                        <div class="uk-margin">
                            <div class="uk-form-label uk-margin-remove">Reimburse Type</div>
                            <div class="uk-form-controls uk-flex uk-flex-middle">
                                <label><input
                                    class="uk-radio"
                                    type="radio"
                                    name="general"
                                    :value="1"
                                    v-model="addReimbursementSetupData.type"
                                    @change="getReimbursementSetupType"
                                > General</label>
                                <label><input
                                    class="uk-radio uk-margin-medium-left"
                                    type="radio"
                                    name="voucher"
                                    :value="2"
                                    v-model="addReimbursementSetupData.type"
                                    @change="getReimbursementSetupType"
                                > Voucher</label>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="amount">Amount</label>
                            <div class="uk-form-controls">
                                <input
                                    v-if="!activeInput"
                                    class="uk-border-rounded myrobin-background-grey-1"
                                    id="amount"
                                    type="text"
                                    placeholder="By Request"
                                    disabled
                                    v-model="addReimbursementSetupData.general_amount"
                                >

                                <div
                                    v-if="activeInput"
                                    class="uk-inline uk-width-1-1"
                                >
                                    <span class="uk-form-icon">Rp</span>
                                    <input
                                        class="uk-input uk-border-rounded"
                                        id="amount"
                                        type="number"
                                        min="0"
                                        v-model="addReimbursementSetupData.voucher_amount"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="min-work-duration">Minimum work duration</label>
                            <div class="uk-form-controls">
                                <div
                                    class="uk-input uk-margin-remove uk-padding-remove uk-border-rounded"
                                    style="overflow: hidden;"
                                    uk-grid
                                >
                                    <input
                                        class="uk-width-expand uk-input"
                                        id="min-work-duration"
                                        style="padding: 0 15px; margin: -1px -1px 0 -1px;"
                                        type="number"
                                        min="0"
                                        v-model="addReimbursementSetupData.min_work_duration"
                                    >
                                    <span class="uk-width-auto myrobin-background-grey-1" style="padding: 0 15px; color: black;">Months</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="request-duration">Can be requested every</label>
                            <div class="uk-form-controls">
                                <input
                                    v-if="!activeInput"
                                    class="uk-border-rounded"
                                    id="request-duration"
                                    type="text"
                                    placeholder="By Request"
                                    disabled
                                    v-model="addReimbursementSetupData.general_request_duration"
                                >

                                <div
                                    v-if="activeInput"
                                    class="uk-input uk-margin-remove uk-padding-remove uk-border-rounded"
                                    style="overflow: hidden;"
                                    uk-grid
                                >
                                    <input
                                        class="uk-width-expand uk-input"
                                        id="min-work-duration"
                                        style="padding: 0 15px; margin: -1px -1px 0 -1px;"
                                        type="number"
                                        min="0"
                                        v-model="addReimbursementSetupData.voucher_request_duration"
                                    >
                                    <span class="uk-width-auto myrobin-background-grey-1" style="padding: 0 15px; color: black;">Months</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="company-office">Company Office</label>
                            <div class="uk-form-controls">
                                <select
                                    class="uk-select uk-border-rounded"
                                    id="company-office"
                                    v-model="addReimbursementSetupData.company_office_id"
                                >
                                    <option
                                        :value="null"
                                        selected
                                    >All Office</option>
                                    <option
                                        v-for="(office, index) in company_offices"
                                        :key="index"
                                        :value="office._id"
                                    >{{ office.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="role-id">Apply for Role</label>
                            <div class="uk-form-controls">
                                <select
                                    class="uk-select uk-border-rounded"
                                    id="role-id"
                                    v-model="addReimbursementSetupData.role_id"
                                >
                                    <option
                                        :value="null"
                                        selected
                                    >All Role</option>
                                    <option
                                        v-for="(role, index) in roles"
                                        :key="index"
                                        :value="role._id"
                                    >{{ role.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-margin uk-text-right">
                            <button
                                class="uk-button"
                                :class="[buttonSaveValidation || is_loading.create ? 'uk-button-default myrobin-button-disabled' : 'uk-button-primary']"
                                type="submit"
                                :disabled="buttonSaveValidation || is_loading.create"
                            >{{ is_loading.create ? 'Loading...' : 'Save' }}</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End add reimbursement setup modal box -->

            <!-- Edit reimbursement setup modal box -->
            <div id="edit-reimbursement-setup" class="uk-flex-top edit-reimbursement-setup" uk-modal esc-close="false" bg-close="true">
                <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical border-radius-10px">
                    <p class="uk-text-bold">Edit Reimbursement Setup</p>
                    <form class="uk-form-horizontal uk-margin-medium-top" @submit.prevent="updateReimbursementSetup">
                        <div class="uk-margin">
                            <div class="uk-form-label uk-margin-remove">Reimburse Type</div>
                            <div class="uk-form-controls uk-flex uk-flex-middle">
                                <label><input
                                    class="uk-radio"
                                    type="radio"
                                    name="general"
                                    :value="1"
                                    v-model="addReimbursementSetupData.type"
                                    @change="getReimbursementSetupType"
                                > General</label>
                                <label><input
                                    class="uk-radio uk-margin-medium-left"
                                    type="radio"
                                    name="voucher"
                                    :value="2"
                                    v-model="addReimbursementSetupData.type"
                                    @change="getReimbursementSetupType"
                                > Voucher</label>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="amount">Amount</label>
                            <div class="uk-form-controls">
                                <input
                                    v-if="!activeInput"
                                    class="uk-border-rounded"
                                    id="amount"
                                    type="text"
                                    placeholder="By Request"
                                    disabled
                                    v-model="addReimbursementSetupData.general_amount"
                                >

                                <div
                                    v-if="activeInput"
                                    class="uk-inline uk-width-1-1"
                                >
                                    <span class="uk-form-icon">Rp</span>
                                    <input
                                        class="uk-input uk-border-rounded"
                                        id="amount"
                                        type="number"
                                        min="0"
                                        v-model="addReimbursementSetupData.voucher_amount"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="min-work-duration">Minimum work duration</label>
                            <div class="uk-form-controls">
                                <div
                                    class="uk-input uk-margin-remove uk-padding-remove uk-border-rounded"
                                    style="overflow: hidden;"
                                    uk-grid
                                >
                                    <input
                                        class="uk-width-expand uk-input"
                                        id="min-work-duration"
                                        style="padding: 0 15px; margin: -1px -1px 0 -1px;"
                                        type="number"
                                        min="0"
                                        v-model="addReimbursementSetupData.min_work_duration"
                                    >
                                    <span class="uk-width-auto myrobin-background-grey-1" style="padding: 0 15px; color: black;">Months</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="request-duration">Can be requested every</label>
                            <div class="uk-form-controls">
                                <input
                                    v-if="!activeInput"
                                    class="uk-border-rounded"
                                    id="request-duration"
                                    type="text"
                                    placeholder="By Request"
                                    disabled
                                    v-model="addReimbursementSetupData.general_request_duration"
                                >

                                <div
                                    v-if="activeInput"
                                    class="uk-input uk-margin-remove uk-padding-remove uk-border-rounded"
                                    style="overflow: hidden;"
                                    uk-grid
                                >
                                    <input
                                        class="uk-width-expand uk-input"
                                        id="min-work-duration"
                                        style="padding: 0 15px; margin: -1px -1px 0 -1px;"
                                        type="number"
                                        min="0"
                                        v-model="addReimbursementSetupData.voucher_request_duration"
                                    >
                                    <span class="uk-width-auto myrobin-background-grey-1" style="padding: 0 15px; color: black;">Months</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="company-office">Company Office</label>
                            <div class="uk-form-controls">
                                <select
                                    class="uk-select uk-border-rounded"
                                    id="company-office"
                                    v-model="addReimbursementSetupData.company_office_id"
                                >
                                    <option
                                        :value="null"
                                        selected
                                    >All Office</option>
                                    <option
                                        v-for="(office, index) in company_offices"
                                        :key="index"
                                        :value="office._id"
                                    >{{ office.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="role-id">Apply for Role</label>
                            <div class="uk-form-controls">
                                <select
                                    class="uk-select uk-border-rounded"
                                    id="role-id"
                                    v-model="addReimbursementSetupData.role_id"
                                >
                                    <option
                                        :value="null"
                                        selected
                                    >All Role</option>
                                    <option
                                        v-for="(role, index) in roles"
                                        :key="index"
                                        :value="role._id"
                                    >{{ role.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-margin uk-text-right">
                            <button
                                class="uk-button"
                                :class="[buttonSaveValidation || is_loading.create ? 'uk-button-default myrobin-button-disabled' : 'uk-button-primary']"
                                type="submit"
                                :disabled="buttonSaveValidation || is_loading.create"
                            >{{ is_loading.create ? 'Loading...' : 'Save' }}</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End edit reimbursement setup modal box -->

            <!-- Delete reimbursement setup modal box -->
            <div id="delete-reimbursement-setup" class="uk-flex-top delete-reimbursement-setup" uk-modal esc-close="false" bg-close="true">
                <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical border-radius-10px">
                    <p class="uk-text-bold">Are you sure to delete this setup?</p>
                    <form class="uk-form-horizontal uk-margin-medium-top" @submit.prevent="removeReimbursementSetup">
                        <div class="uk-margin">
                            <div class="uk-form-label uk-margin-remove">Reimburse Type</div>
                            <div class="uk-form-controls uk-flex uk-flex-middle">
                                <label><input
                                    class="uk-radio"
                                    type="radio"
                                    name="general"
                                    :value="1"
                                    v-model="addReimbursementSetupData.type"
                                    disabled
                                > General</label>
                                <label><input
                                    class="uk-radio uk-margin-medium-left"
                                    type="radio"
                                    name="voucher"
                                    :value="2"
                                    v-model="addReimbursementSetupData.type"
                                    disabled
                                > Voucher</label>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="amount">Amount</label>
                            <div class="uk-form-controls">
                                <input
                                    v-if="!activeInput"
                                    class="uk-border-rounded"
                                    id="amount"
                                    type="text"
                                    placeholder="By Request"
                                    disabled
                                    v-model="addReimbursementSetupData.general_amount"
                                >

                                <div
                                    v-if="activeInput"
                                    class="uk-inline uk-width-1-1"
                                >
                                    <span class="uk-form-icon">Rp</span>
                                    <input
                                        class="uk-input uk-border-rounded"
                                        id="amount"
                                        type="number"
                                        min="0"
                                        disabled
                                        v-model="addReimbursementSetupData.voucher_amount"
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="min-work-duration">Minimum work duration</label>
                            <div class="uk-form-controls">
                                <div
                                    class="uk-input uk-margin-remove uk-padding-remove uk-border-rounded"
                                    style="overflow: hidden;"
                                    uk-grid
                                >
                                    <input
                                        class="uk-width-expand uk-input"
                                        id="min-work-duration"
                                        style="padding: 0 15px; margin: -1px -1px 0 -1px;"
                                        type="number"
                                        min="0"
                                        disabled
                                        v-model="addReimbursementSetupData.min_work_duration"
                                    >
                                    <span class="uk-width-auto myrobin-background-grey-1" style="padding: 0 15px; color: black;">Months</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="request-duration">Can be requested every</label>
                            <div class="uk-form-controls">
                                <input
                                    v-if="!activeInput"
                                    class="uk-border-rounded"
                                    id="request-duration"
                                    type="text"
                                    placeholder="By Request"
                                    disabled
                                    v-model="addReimbursementSetupData.general_request_duration"
                                >

                                <div
                                    v-if="activeInput"
                                    class="uk-input uk-margin-remove uk-padding-remove uk-border-rounded"
                                    style="overflow: hidden;"
                                    uk-grid
                                >
                                    <input
                                        class="uk-width-expand uk-input"
                                        id="min-work-duration"
                                        style="padding: 0 15px; margin: -1px -1px 0 -1px;"
                                        type="number"
                                        min="0"
                                        disabled
                                        v-model="addReimbursementSetupData.voucher_request_duration"
                                    >
                                    <span class="uk-width-auto myrobin-background-grey-1" style="padding: 0 15px; color: black;">Months</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="company-office">Company Office</label>
                            <div class="uk-form-controls">
                                <select
                                    class="uk-select uk-border-rounded"
                                    id="company-office"
                                    disabled
                                    v-model="addReimbursementSetupData.company_office_id"
                                >
                                    <option
                                        :value="null"
                                        selected
                                    >All Office</option>
                                    <option
                                        v-for="(office, index) in company_offices"
                                        :key="index"
                                        :value="office._id"
                                    >{{ office.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-margin">
                            <label class="uk-form-label" for="role-id">Apply for Role</label>
                            <div class="uk-form-controls">
                                <select
                                    class="uk-select uk-border-rounded"
                                    id="role-id"
                                    disabled
                                    v-model="addReimbursementSetupData.role_id"
                                >
                                    <option
                                        :value="null"
                                        selected
                                    >All Role</option>
                                    <option
                                        v-for="(role, index) in roles"
                                        :key="index"
                                        :value="role._id"
                                    >{{ role.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-margin uk-text-right">
                            <button
                                class="uk-button uk-button-danger uk-border-rounded"
                                :class="[buttonSaveValidation || is_loading.create ? 'uk-button-default myrobin-button-disabled' : 'uk-button-primary']"
                                type="submit"
                                :disabled="buttonSaveValidation || is_loading.create"
                            >{{ is_loading.create ? 'Loading...' : 'Delete' }}</button>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End delete reimbursement setup modal box -->
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';
import { mapActions, mapGetters } from 'vuex';
import Pagination from '@/components/globals/Pagination';
import EmptyTable from '@/components/globals/tables/EmptyTable';
import formatter from "@/utils/formatter";
import { getUserLogin } from '@/utils/auth';
import {
    notificationSuccess
} from '@/utils/notification';

export default {
    name: 'ReimburseSetup',
    components: {
        Pagination,
        EmptyTable
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            is_loading: {
                init: false,
                create: false,
                update: false
            },
            addReimbursementSetupData: {
                type: 1,
                general_amount: null,
                voucher_amount: 0,
                min_work_duration: 0,
                general_request_duration: null,
                voucher_request_duration: 0,
                company_office_id: null,
                role_id: null
            },
            activeInput: false,
            reimbursementDatas: null,
            reimbursement_id: null,
            reimbursement_type: 1,
            reimbursementData: null,
            meta: {
                limit: 10,
                page: 1
            }
        };
    },
    watch: {
        async meta() {
            this.setReimbursementDatas();
        }
    },
    computed : {
        ...mapGetters({
            company_offices: 'new_shift/company_offices',
            roles: 'option_data/roles'
        }),
        buttonSaveValidation() {
            return (
                this.addReimbursementSetupData.min_work_duration === 0 ||
                (this.addReimbursementSetupData.type === 1 ?
                    (this.addReimbursementSetupData.general_amount || this.addReimbursementSetupData.general_request_duration) :
                    (this.addReimbursementSetupData.voucher_amount === 0 || this.addReimbursementSetupData.voucher_request_duration === 0))
            );
        },
        user_cred() {
            return getUserLogin();
        },
    },
    beforeMount() {
        if(window.UIkit.modal('.add-reimbursement-setup')) window.UIkit.modal('.add-reimbursement-setup').$destroy(true);
        if(window.UIkit.modal('.edit-reimbursement-setup')) window.UIkit.modal('.edit-reimbursement-setup').$destroy(true);
        if(window.UIkit.modal('.delete-reimbursement-setup')) window.UIkit.modal('.delete-reimbursement-setup').$destroy(true);
    },
    async mounted() {
        this.is_loading.init = true;
        await this.getCompanyOffice();
        await this.getRole();
        await this.setReimbursementDatas();
        this.is_loading.init = false;
    },
    methods: {
        ...mapActions({
            getCompanyOffice: 'new_shift/getCompanyOffice',
            getRole: 'option_data/getRoles',
            createReimbursementSetup: 'salary_settings/createReimbursementSetup',
            getReimbursementSetupList: 'salary_settings/getReimbursementSetupList',
            getDetailReimbursementSetup: 'salary_settings/getDetailReimbursementSetup',
            editReimbursementSetup: 'salary_settings/editReimbursementSetup',
            deleteReimbursementSetup: 'salary_settings/deleteReimbursementSetup'
        }),
        consist(user_roles, value){
            return value.some(i => user_roles.includes(i));
        },
        async addReimbursementSetup() {
            try {
                this.is_loading.create = true;
                const payload = {
                    type: this.addReimbursementSetupData.type,
                    amount: this.addReimbursementSetupData.type === 1 ? this.addReimbursementSetupData.general_amount : this.addReimbursementSetupData.voucher_amount,
                    min_work_duration: this.addReimbursementSetupData.min_work_duration,
                    request_duration: this.addReimbursementSetupData.type === 1 ? this.addReimbursementSetupData.general_request_duration : this.addReimbursementSetupData.voucher_request_duration,
                    company_office_id: this.addReimbursementSetupData.company_office_id,
                    role_id: this.addReimbursementSetupData.role_id
                };

                const response = await this.createReimbursementSetup(payload);
                if(response.status == 'OK' && response.message == 'Created') {
                    notificationSuccess(`Reimbursement setup successfully created`);
                    this.hideModal('add-reimbursement-setup');
                    await this.setReimbursementDatas();
                    this.addReimbursementSetupData.type = 1;
                    this.resetForm();
                    this.is_loading.create = false;
                } else {
                    this.is_loading.create = false;
                }
            } catch (error) {
                this.is_loading.create = false;
            }
        },
        async updateReimbursementSetup() {
            try {
                this.is_loading.create = true;
                const payload = {
                    _id: this.reimbursement_id,
                    type: this.addReimbursementSetupData.type,
                    amount: this.addReimbursementSetupData.type === 1 ? this.addReimbursementSetupData.general_amount : this.addReimbursementSetupData.voucher_amount,
                    min_work_duration: this.addReimbursementSetupData.min_work_duration,
                    request_duration: this.addReimbursementSetupData.type === 1 ? this.addReimbursementSetupData.general_request_duration : this.addReimbursementSetupData.voucher_request_duration,
                    company_office_id: this.addReimbursementSetupData.company_office_id,
                    role_id: this.addReimbursementSetupData.role_id
                };

                const response = await this.editReimbursementSetup(payload);
                if(response.status == 'OK' && response.message == 'Edited') {
                    notificationSuccess(`Reimbursement setup successfully updated`);
                    await this.setReimbursementDatas();
                    this.hideModal('edit-reimbursement-setup');
                    this.addReimbursementSetupData.type = 1;
                    this.resetForm();
                    this.is_loading.create = false;
                } else {
                    this.is_loading.create = false;
                }
            } catch (error) {
                this.is_loading.create = false;
            }
        },
        async removeReimbursementSetup() {
            try {
                this.is_loading.create = true;
                const payload = {
                    _id: this.reimbursement_id
                };

                const response = await this.deleteReimbursementSetup(payload);
                if(response.status == 'OK' && response.message == 'Deleted') {
                    notificationSuccess(`Reimbursement setup successfully deleted`);
                    await this.setReimbursementDatas();
                    this.hideModal('delete-reimbursement-setup');
                    this.addReimbursementSetupData.type = 1;
                    this.resetForm();
                    this.is_loading.create = false;
                } else {
                    this.is_loading.create = false;
                }
            } catch (error) {
                this.is_loading.create = false;
            }
        },
        async setReimbursementDatas() {
            const response = await this.getReimbursementSetupList(this.meta);
            this.reimbursementDatas = response;
        },
        async setReimbursementData(reimbursement_id) {
            const payload = { _id: reimbursement_id };
            const response = await this.getDetailReimbursementSetup(payload);
            this.reimbursementData = response;
        },
        async showModal(modal_name, reimbursement_id) {
            switch (modal_name) {
            case 'add-reimbursement-setup':
                this.reimbursement_id = null;
                this.addReimbursementSetupData.type = 1;
                this.resetForm();
                this.addReimbursementSetupData.type === 1 ? this.activeInput = false : this.activeInput = true;
                await window.UIkit.modal('#add-reimbursement-setup').show();
                break;
            case 'edit-reimbursement-setup':
                await this.setReimbursementData(reimbursement_id);
                this.reimbursementData.type === 1 ? this.activeInput = false : this.activeInput = true;
                this.addReimbursementSetupData.type = this.reimbursementData.type;
                this.setInputForm(this.reimbursementData);
                await window.UIkit.modal('#edit-reimbursement-setup').show();
                break;
            case 'delete-reimbursement-setup':
                await this.setReimbursementData(reimbursement_id);
                this.reimbursementData.type === 1 ? this.activeInput = false : this.activeInput = true;
                this.addReimbursementSetupData.type = this.reimbursementData.type;
                this.setInputForm(this.reimbursementData);
                await window.UIkit.modal('#delete-reimbursement-setup').show();
                break;
            default:
                break;
            }
        },
        async hideModal(modal_name) {
            switch (modal_name) {
            case 'add-reimbursement-setup':
                await window.UIkit.modal('#add-reimbursement-setup').hide();
                break;
            case 'edit-reimbursement-setup':
                await window.UIkit.modal('#edit-reimbursement-setup').hide();
                break;
            case 'delete-reimbursement-setup':
                await window.UIkit.modal('#delete-reimbursement-setup').hide();
                break;
            default:
                break;
            }
        },
        getReimbursementSetupType() {
            this.addReimbursementSetupData.type === 1 ? this.activeInput = false : this.activeInput = true;
            if(this.reimbursement_id === null) {
                this.resetForm();
            } else {
                this.setReimbursementData(this.reimbursement_id);
                this.setInputForm(this.reimbursementData);
            }
        },
        setInputForm(data) {
            this.reimbursement_id = data._id;
            this.addReimbursementSetupData.company_office_id = data.company_office ? data.company_office._id : null;
            this.addReimbursementSetupData.role_id = data.role ? data.role._id : null;
            this.addReimbursementSetupData.min_work_duration = data.min_work_duration;
            if(data.type === 1) {
                this.addReimbursementSetupData.general_amount = data.amount;
                this.addReimbursementSetupData.general_request_duration = data.request_duration;
                this.addReimbursementSetupData.voucher_amount = 0;
                this.addReimbursementSetupData.voucher_request_duration = 0;
            } else {
                this.addReimbursementSetupData.voucher_amount = data.amount;
                this.addReimbursementSetupData.voucher_request_duration = data.request_duration;
                this.addReimbursementSetupData.general_amount = null;
                this.addReimbursementSetupData.general_request_duration = null;
            }
        },
        resetForm() {
            this.addReimbursementSetupData.min_work_duration = 0;
            this.addReimbursementSetupData.general_amount = null;
            this.addReimbursementSetupData.general_request_duration = null;
            this.addReimbursementSetupData.voucher_amount = 0;
            this.addReimbursementSetupData.voucher_request_duration = 0;
            this.addReimbursementSetupData.company_office_id = null;
            this.addReimbursementSetupData.role_id = null;
        },
        formatAmount(data) {
            if(data == null) return 'By Request';
            return formatter.thousandSeparator(data);
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        }
    }
};
</script>

<style scoped>
.myrobin-text-1 {
    color: #5FD855;
}

.myrobin-background-grey-1 {
    background: #EDEDED;
}

thead {
    background-color: #0ABAB5;
}

th {
    color: #ffffff;
}

.border-radius-10px {
    border-radius: 10px;
}

.myrobin-button-disabled {
    cursor: not-allowed;
}

input[type=text]:disabled {
    background: #EDEDED;
    width: 100%;
    padding: 7.3px 10px;
    cursor: not-allowed;
}
</style>
